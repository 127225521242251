import { dictionary } from '@/plugins/api';

function initialState() {
  return {
    region: [],
    currency: [],
    measure: [],
    warehouse: [],
    colour: [],
    errordictionary: [],
    status: [],
  };
}

const getters = {
  getDictionary: (state) => (entity) => state[entity],
};

const mutations = {
  setDictionary(state, payload) {
    state[payload.storeName] = payload.data;
  },
};

const actions = {
  async getData({ commit, dispatch }, { url, params = {}, storeName }) {
    dispatch('actionSetLoading', true, { root: true });
    const response = await dictionary.get(url, { params: { ...params } });
    dispatch('actionSetLoading', false, { root: true });
    commit('setDictionary', { ...response, storeName });
    return response;
  },
  async postData({ dispatch }, { url, body }) {
    dispatch('actionSetLoading', true, { root: true });
    let response;
    try {
      await dictionary.post(url, body).then((res) => {
        response = res.succeeded;
        if (!response) {
          // res.errors.forEach((i) => {
          //   // eslint-disable-next-line no-underscore-dangle
          //   this._vm.$toast.warning(i);
          // });
        }
      });
    } catch (e) {
      response = e;
    }
    dispatch('actionSetLoading', false, { root: true });
    return response;
  },
  async putData({ dispatch }, { url, body, id }) {
    let response;
    dispatch('actionSetLoading', true, { root: true });
    try {
      response = await dictionary.put(`${url}/${id}`, body);
    } catch (e) {
      response = false;
    }
    dispatch('actionSetLoading', false, { root: true });
    return response;
  },
  async removeData({ dispatch }, { url, id }) {
    let response;
    try {
      await dispatch('tools/showConfirm', {
        title: 'Подтвердите',
        text: 'Вы действительно хотите удалить?',
      }, { root: true });

      dispatch('actionSetLoading', true, { root: true });
      response = await dictionary.delete(`${url}/${id}`);
    } catch (e) {
      response = false;
    }
    dispatch('actionSetLoading', false, { root: true });
    return response;
  },
};

const state = {
  ...initialState(),
  totalElements: null,
  totalPages: null,
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
