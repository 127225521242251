const rolePermissions = [
  {
    name: 'ROLE_ACCEPTANCE_MANAGER',
    pages: {
      // CRM: {},
      // WMS: {},
      // Home: {},
    },
  },
  {
    name: 'ROLE_ACCOUNTANT',
    pages: {
      CRM: {},
      Home: {},
      orders: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'orders-all': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'orders-delivery': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      billing: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'billing-in': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'billing-out': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      bonuses: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'bonuses-accounts': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'bonuses-operations': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      settings: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      clients: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'clients-refunds': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
    },
  },
  {
    name: 'ROLE_ADMIN',
    pages: {
      CRM: {},
      WMS: {},
      DICTIONARIES: {},
      Home: {},
      orders: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'orders-all': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'orders-delivery': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      estimations: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      clients: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'clients-list': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'clients-refunds': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      billing: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'billing-in': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'billing-out': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      bonuses: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'bonuses-accounts': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'bonuses-operations': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      settings: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      feedbacks: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      counterparties: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      suppliers: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'supplier-info': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      contracts: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      catalogs: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      marketplace: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      showcases: {
        read: true,
        update: false,
        create: true,
        delete: false
      },
      showcase: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      nomenclature: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      operations: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      return: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      reception: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'supplier-deliveries': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'supplier-delivery': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      acceptance: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      posting: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      moving: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      writeoff: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      inventory: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      analytics: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'content-mgmt': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'manage-section': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      delivery: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'shopping-carts': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      warehouses: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      region: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      measures: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      currency: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      colours: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      status: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      errors: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      news: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      balance: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      appeals: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      marketing: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      events: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      promocodes: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      reports: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'catalog-order': {
        read: true,
        update: false,
        create: false,
        delete: false
      }
    },
  },
  {
    name: 'ROLE_AHO_MANAGER',
    pages: {},
  },
  {
    name: 'ROLE_CALL_CENTER_MANAGER',
    pages: {
      CRM: {},
      Home: {},
      orders: {
        create: false,
        read: true,
        update: true,
        delete: false,
      },
      'orders-all': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'orders-delivery': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      clients: {
        create: false,
        read: true,
        update: true,
        delete: false,
      },
      'clients-list': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'clients-refunds': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      appeals: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      feedbacks: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
    },
  },
  {
    name: 'ROLE_CALL_CENTER_OPERATOR',
    pages: {
      CRM: {},
      Home: {},
      orders: {
        create: false,
        read: true,
        update: true,
        delete: false,
      },
      'orders-all': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'orders-delivery': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      clients: {
        create: false,
        read: true,
        update: true,
        delete: false,
      },
      'clients-list': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'clients-refunds': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      appeals: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      feedbacks: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
    },
  },
  {
    name: 'ROLE_CATEGORY_MANAGER_NON_FOOD',
    pages: {
      CRM: {},
      WMS: {},
      Home: {},
      orders: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'orders-all': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'orders-delivery': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      clients: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'clients-list': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'clients-refunds': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      billing: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'billing-in': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'billing-out': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      bonuses: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'bonuses-accounts': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'bonuses-operations': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      feedbacks: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      counterparties: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      suppliers: {
        create: true,
        read: true,
        update: false,
        delete: false,
      },
      'supplier-info': {
        create: true,
        read: true,
        update: false,
        delete: false,
      },
      contracts: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      catalogs: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      nomenclature: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      marketplace: {
        read: true,
        update: false,
        create: false,
        delete: false
      },
      showcases: {
        read: true,
        update: false,
        create: false,
        delete: false
      },
      'catalog-order': {
        read: true,
        update: false,
        create: false,
        delete: false
      }
    },
  },
  {
    name: 'ROLE_COLLECTOR_PACKER',
    pages: {
      // CRM: {},
      // WMS: {},
      // Home: {},
    },
  },
  {
    name: 'ROLE_COURIER',
    pages: {
      // CRM: {},
      // WMS: {},
      // Home: {},
    },
  },
  {
    name: 'ROLE_CRM_MANAGER',
    pages: {
      // CRM: {},
      // WMS: {},
      // Home: {},
    },
  },
  {
    name: 'ROLE_LOGIST',
    pages: {
      Home: {},
      CRM: {},
      WMS: {},
      operations: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      delivery: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      orders: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'orders-all': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'orders-delivery': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
    },
  },
  {
    name: 'ROLE_MARKETER',
    pages: {
      CRM: {},
      Home: {},
      marketplace: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      showcases: {
        read: true,
        update: false,
        create: true,
        delete: false
      },
      showcase: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      catalogs: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      analytics: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'content-mgmt': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'manage-section': {
        create: true,
        read: true,
        update: false,
        delete: false,
      },
      'shopping-carts': {
        create: true,
        read: true,
        update: false,
        delete: false,
      },
      news: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      marketing: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      events: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      promocodes: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
    },
  },
  {
    name: 'ROLE_PICKUP_POINT_MANAGER',
    pages: {
      CRM: {},
      Home: {},
      orders: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'orders-all': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'orders-delivery': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      clients: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'clients-refunds': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
    },
  },
  {
    name: 'ROLE_SHIPPING_MANAGER',
    pages: {
      Home: {},
      WMS: {},
      operations: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      delivery: {
        create: false,
        read: true,
        update: true,
        delete: false,
      },
    },
  },
  {
    name: 'ROLE_WAREHOUSE_MANAGER',
    pages: {
      WMS: {},
      CRM: {},
      Home: {},
      operations: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      return: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      reception: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'supplier-deliveries': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      'supplier-delivery': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      acceptance: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      posting: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      moving: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      writeoff: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      inventory: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      balance: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      orders: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'orders-all': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      clients: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'clients-refunds': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
    },
  },
  {
    name: 'ROLE_JIVO_USER',
    pages: {
      CRM: {},
      Home: {},
      'jivo-seller': {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
    },
  },
  {
    name: 'ROLE_ANALYTIC',
    pages: {
      CRM: {},
      Home: {},
      reports: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      analytics: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      orders: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'orders-all': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      clients: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'clients-list': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
    },
  },
  {
    name: 'ROLE_CHIEF',
    pages: {
      CRM: {},
      WMS: {},
      DICTIONARIES: {},
      Home: {},
      orders: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'orders-all': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'orders-delivery': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      estimations: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      clients: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'clients-list': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'clients-refunds': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      billing: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'billing-in': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'billing-out': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      bonuses: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'bonuses-accounts': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'bonuses-operations': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      settings: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      feedbacks: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      counterparties: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      suppliers: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'supplier-info': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      contracts: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      catalogs: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      marketplace: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      showcases: {
        read: true,
        update: false,
        create: false,
        delete: false
      },
      showcase: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      nomenclature: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      operations: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      return: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      reception: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'supplier-deliveries': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'supplier-delivery': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      acceptance: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      posting: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      moving: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      writeoff: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      inventory: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      analytics: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'content-mgmt': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'manage-section': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      delivery: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      'shopping-carts': {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      warehouses: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      region: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      measures: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      currency: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      colours: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      status: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      errors: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      news: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      balance: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      appeals: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      marketing: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      events: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      promocodes: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      reports: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
    },
  },
];

export default rolePermissions;

/*

ROLE_ACCEPTANCE_MANAGER;
ROLE_ACCOUNTANT;
ROLE_ADMIN;
ROLE_AHO_MANAGER;
ROLE_CALL_CENTER_MANAGER;
ROLE_CALL_CENTER_OPERATOR;
ROLE_CATEGORY_MANAGER_NON_FOOD;
ROLE_COLLECTOR_PACKER;
ROLE_COURIER;
ROLE_CRM_MANAGER;
ROLE_LOGIST;
ROLE_MARKETER;
ROLE_PICKUP_POINT_MANAGER;
ROLE_SHIPPING_MANAGER;
ROLE_WAREHOUSE_MANAGER;
ROLE_ANALYTIC;

 */
