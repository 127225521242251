const getters = {
  snackbar: (state) => state.snackbar,
  confirm: (state) => state.confirm,
};

const mutations = {
  set(state, { type, value }) {
    state[type] = value;
  },
};

const actions = {
  setSnackbar({ commit }, payload) {
    if (typeof payload === 'string') {
      const success = {
        type: 'success',
        message: payload,
      };
      commit('set', { type: 'snackbar', value: success });
    } else {
      commit('set', { type: 'snackbar', value: payload });
    }
  },
  showConfirm({ commit, state }, payload) {
    let deferred = null;
    const promise = new Promise(((resolve, reject) => {
      deferred = { resolve, reject };
    }));
    commit('set', {
      type: 'confirm',
      value: {
        show: true,
        title: payload.title,
        text: payload.text,
        resolveText: payload.resolveText || 'Да',
        rejectText: payload.rejectText || 'Нет',
        persistent: payload.persistent || false,
        promise,
        deferred,
      },
    });
    return state.confirm.promise;
  },
  hideConfirm({ commit }) {
    commit('set', {
      type: 'confirm',
      value: {
        show: false,
        title: null,
        text: null,
        resolveText: null,
        rejectText: null,
        persistent: false,
        promise: null,
        deferred: null,
      },
    });
  },

};
function initialState() {
  return {
    snackbar: {},
    confirm: {
      show: false,
      title: null,
      text: null,
      resolveText: null,
      rejectText: null,
      persistent: false,
      promise: null,
      deferred: null,
    },
  };
}
const state = {
  ...initialState(),

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
