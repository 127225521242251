<template>
  <div class="catalog-order pa-6">
    <v-dialog
      v-model="showDialog"
      width="550"
    >
      <v-card>
        <v-card-title>
          <span class="modal-title">
            Каталоги для отображения
          </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="handleCloseDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="max-height: 70vh; overflow-y: scroll">
          <v-checkbox
            v-for="catalog in catalogs"
            :key="catalog.id"
            :label="catalog.nameRu"
            v-model="selectedCatalogs"
            :value="catalog.id"
            hide-details
          ></v-checkbox>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <span class="count-label">Выбрано {{selectedCatalogs.length}} из 18</span>
          <v-spacer/>
          <v-btn
            text
            @click="handleCloseDialog"
          >
            Отменить
          </v-btn>
          <v-btn
            color="primary"
            rounded
            :disabled="selectedCatalogs.length !== 18"
            @click="handleSaveAddCatalog"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <div>
      <v-btn rounded color="primary" class="mr-3" large
             @click="handleAddCatalogs"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        {{buttonLabel}}
      </v-btn>
    </div>
    <div class="pt-5 pb-5">
      <span class="label"> {{label}} </span>
    </div>
    <div class="drag-wrapper">
      <draggable
        :list="orderedCatalogs"
        tag="div"
        class="d-flex flex-wrap"
        style="gap: 8px;"
        @change="handleChangeOrder"
      >
        <div
          v-for="catalog in orderedCatalogs"
          :key="catalog.catalogId"
          class="catalog-item"
        >
          <img
            class="catalog-item__img"
            alt="img"
            :src="getImageURL(catalog.imageUrl)"
          />
          <span class="catalog-item__name"> {{ catalog.name }} </span>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import getEnv from '@/utils/env';

export default {
  name: 'index',
  components: {
    draggable
  },
  data() {
    return {
      showDialog: false,
      catalogs: [],
      selectedCatalogs: [],
      orderedCatalogs: [],
    }
  },
  computed: {
    label() {
      return this.orderedCatalogs.length ? 'Перетащите карточки для изменения порядка' : 'Каталоги не выбраны'
    },
    buttonLabel() {
      return this.orderedCatalogs.length ? 'Изменить' : 'Добавить'
    }
  },
  mounted() {
    this.fetchCatalogs()
    this.fetchSortedCatalogs()
  },
  methods: {
    getImageURL(path) {
      return `${getEnv('VUE_APP_URL_MINIO')}/ecom/${path}`;
    },
    handleAddCatalogs() {
      this.showDialog = true
    },
    handleCloseDialog() {
      this.showDialog = false
    },
    async fetchCatalogs() {
      const response = await this.$catalogService.get('/crm-catalogs');
      if (response?.length) {
        this.catalogs = response.filter(catalog => catalog.status === "ENABLED")
      }
    },
    async fetchSortedCatalogs() {
      const { content } = await this.$catalogService.get('/crm-catalogs/main-page/catalog-sorted')
      if (content?.length) {
        this.orderedCatalogs = content
        this.setSelectedCatalogs(content)
      }
    },
    setSelectedCatalogs(content) {
      this.selectedCatalogs = content.map(catalog => catalog.catalogId)
    },
    async handleSaveAddCatalog() {
      const body = this.selectedCatalogs.map((catalog, index) => ({
        catalogId: catalog,
        ord: index
      }))
      const {succeeded, content} = await this.saveCatalogOrder(body)
      if (succeeded) {
        this.selectedCatalogs.splice(0)
        this.showDialog = false
        this.orderedCatalogs = content
        this.setSelectedCatalogs(content)
      }
    },
    async saveCatalogOrder(body) {
      return await this.$catalogService.post('/crm-catalogs/main-page/catalog-sorted', body)

    },
    async handleChangeOrder() {
      const body = this.orderedCatalogs.map((catalog, index) => ({
        catalogId: catalog.catalogId,
        ord: index
      }))
      await this.saveCatalogOrder(body)
    }
  },
  watch: {
    selectedCatalogs(val) {
      console.log(val);
    }
  }
};
</script>

<style lang="scss">
.catalog-order {
  .label {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #666666;
  }
  .modal-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #1D1D1D;
  }

  .count-label {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #1B1B1B;
  }

  .catalog-item {
    background: #f4f3f3;
    border-radius: 12px;
    position: relative;
    object-fit: contain;
    max-width: 100px;
    max-height: 100px;
    width: 100px;
    height: 100px;
    overflow: hidden;
    cursor: grab;
    &__img {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100px;
      max-height: 100px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }
    &__name {
      z-index: 2;
      position: absolute;
      top: 10px;
      left: 10px;
      white-space: break-spaces;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #1b1b1b;
    }
  }
  .drag-wrapper {
    max-width: 1000px;
  }
}
</style>
