const getters = {
  getCategories: (state) => state.categories,
  getProducts: (state) => state.products,
};

const mutations = {
  setCategories: (state, payload) => {
    state.categories = [...payload];
  },
  setProducts: (state, payload) => {
    state.products = [...payload];
  },
};

const actions = {
  actionSetCategories: ({ commit }, payload) => {
    commit('setCategories', payload);
  },
  actionSetProducts: ({ commit }, payload) => {
    commit('setProducts', payload);
  },
};

const state = {
  categories: [],
  products: [],
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
