import { render, staticRenderFns } from "./cm-table-data.vue?vue&type=template&id=d75b4b56&scoped=true"
import script from "./cm-table-data.vue?vue&type=script&lang=js"
export * from "./cm-table-data.vue?vue&type=script&lang=js"
import style0 from "./cm-table-data.vue?vue&type=style&index=0&id=d75b4b56&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d75b4b56",
  null
  
)

export default component.exports