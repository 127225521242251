import qs from 'qs';
import lodash from 'lodash';
// eslint-disable-next-line no-unused-vars
import { api } from '@/plugins/api';
import Vue from 'vue';

const getters = {
  catalogs: (state) => state.catalogs,
  catalogsBase: (state) => state.catalogsBase,
  catalogsStructure: (state) => state.catalogsStructure,
  filterFields: (state) => state.filterFields,
  catalogFilters: (state) => lodash.groupBy(state.catalogFilters, 'code'),
  nomenclature: (state) => (!!state.catalogFilters.find((el) => el.code === 'NOMENCLATURE_ID')),
  selectedFilters: (state) => state.selectedFilters,
  products: (state) => state.products,
  // selectedFilters: (state) => Object.keys(lodash.groupBy(state.catalogFilters, 'type')),
};

const mutations = {
  SET_CATALOGS_BASE(state, catalogs) {
    state.catalogsBase = [...catalogs];
  },
  SET_CATALOGS(state, catalogs) {
    state.catalogs = [...catalogs];
  },
  SET_CATALOG_STRUCTURE(state, structure) {
    state.catalogsStructure = [...structure];
  },
  SET_FILTER_FIELDS(state, fields) {
    state.filterFields = [...fields];
  },
  SET_CATALOG_FILTERS(state, filters) {
    state.catalogFilters = [...filters];
  },
  PUSH_NEW_FILTER(state, filter) {
    const index = state.catalogFilters.findIndex(
      (el) => el.code === filter.code || el.code === `FIELD_${filter.code}`,
    );
    if (index > -1) {
      const index2 = state.catalogFilters[index].valueList.findIndex((el) => el === filter.value);
      if (index2 === -1) state.catalogFilters[index].valueList.push(filter.value);
    } else {
      state.catalogFilters.push({
        code: typeof filter.code === 'number' ? `FIELD_${filter.code}` : filter.code,
        valueList: [filter.value],
      });
    }
  },
  REMOVE_FILTER(state, filter) {
    const index = state.catalogFilters.findIndex((el) => el.code === filter.code);
    const index2 = state.catalogFilters[index].valueList.findIndex((el) => el === filter.value);
    state.catalogFilters[index].valueList.splice(index2, 1);
  },
  REMOVE_FILTER_FROM_LIST(state, filter) {
    const index = state.catalogFilters.findIndex((el) => el.code === filter.code);
    state.catalogFilters.splice(index, 1);
  },
  SET_SELECTED_FILTER(state, selectedFilters) {
    const selectedFiltersRemoveFIELD = selectedFilters.map((el) => (el.indexOf('FIELD') > -1 ? +el.slice(6) : el));

    state.selectedFilters = selectedFiltersRemoveFIELD;
  },
  PUSH_SELECTED_FILTER(state, filter) {
    const index = state.selectedFilters.findIndex((el) => el === filter);
    if (index === -1) {
      state.selectedFilters.push(filter);
    }
  },
  REMOVE_SELECTED_FILTER(state, filter) {
    const index = state.selectedFilters.findIndex(filter);
    state.selectedFilters.splice(index, 1);
  },
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_SELECTED_PRODUCTS(state, products) {
    state.fetchedSelectedProducts = [...products];
  },
  // setSuppliers: (state, payload) => {
  //   state.suppliers = [...payload];
  // },
};

const actions = {
  async fetchCatalogs({ dispatch, commit }) {
    try {
      const response = await Vue.prototype.$catalogService.get('/crm-catalogs');

      // eslint-disable-next-line no-inner-declarations
      function extract(array) {
        return array.map((item) => {
          const obj = lodash.pick(item, ['id', 'children']);
          if (obj.children && obj.children.length > 0) { // Array.isArray(obj.children)
            return {
              ...obj,
              children: extract(obj.children),
            };
          }
          delete obj.children;
          return obj;
        })
          .filter((x) => (x.id !== -1));
      }

      // eslint-disable-next-line no-inner-declarations
      function add(arr) {
        for (let i = 0; i < arr.length; i += 1) {
          if (arr[i].level !== 2) add(arr[i].children, arr[i].id);
        }
        // arr.push({ id: -1, parentId });
        return arr;
      }

      const nest = (items, id = null, link = 'parentId') => items
        .sort((a, b) => a.id - b.id)
        .filter((item) => item[link] === id)
        .map((item) => ({
          ...item,
          children: nest(items, item.id),
        }));

      commit('SET_CATALOGS_BASE', response);
      /** после получения списка каталогов, вызвать структуру каталогов */
      const structure = await Vue.prototype.$catalogService.get('/crm-catalogs/structure');

      /** При первом запуске структура каталога не существует */
      if (structure) {
        commit('SET_CATALOG_STRUCTURE', structure);
      } else {
        await dispatch('saveCatalogStructure', extract(add(nest(response), null)));
        commit('SET_CATALOG_STRUCTURE', structure);
      }

      /** убрал добавление распределенныых каталогов */
      // commit('SET_CATALOGS', add(nest(response), null));
    } catch (e) {
      console.log(e);
    }
  },
  // async getCatalogStructure({ commit }) {
  //   const response = await api.get('/crm-catalogs/structure');
  //   commit('SET_CATALOG_STRUCTURE', response);
  // },
  async setNewCatalogs({ dispatch }, payload) {
    const body = payload;
    const newCatalog = await Vue.prototype.$catalogService.post('/crm-catalogs', body)
      .then((res) => res);
    dispatch('fetchCatalogs');
    return newCatalog;
  },
  async updateCatalog({ dispatch }, payload) {
    const body = payload;
    const updatedCatalog = await Vue.prototype.$catalogService.put(`/crm-catalogs/${body.id}`, body)
      .then((res) => res)
      .catch((e) => {
        if (e?.response?.data?.Message) {
          // eslint-disable-next-line no-underscore-dangle
          this._vm.$toast.warning(e.response.data.Message);
        }
      });
    dispatch('fetchCatalogs');
    return updatedCatalog;
  },
  // eslint-disable-next-line no-unused-vars
  async saveCatalogStructure({ dispatch }, payload) {
    const body = payload;
    await Vue.prototype.$catalogService.post('/crm-catalogs/structure', body);
    // dispatch('getCatalogStructure');
    // return 1;
  },
  async updateStatus({ dispatch }, payload) {
    const { id, status } = payload;
    const url = `/crm-catalogs/${status ? 'enable' : 'disable'}/${id}`;
    const updatedStatus = await Vue.prototype.$catalogService.post(url, null);
    dispatch('fetchCatalogs');
    return updatedStatus;
  },
  async updateAdultStatus({ dispatch }, payload) {
    const { id, isAgeLimit } = payload;
    const url = `/update-is-age-limit`;
    const updatedStatus = (await Vue.prototype.$catalogService.put(url, { id, isAgeLimit })).content;
    dispatch('fetchCatalogs');
    return updatedStatus;
  },
  async getFilters({ commit, dispatch }, payload) {
    const { catalogId } = payload;
    const response = await Vue.prototype.$catalogService.get(`/crm-catalogs/query/${catalogId}`);

    await commit('SET_CATALOG_FILTERS', response);
    if (response.length > 0) {
      /** FIXME: Вызывается во второй раз при добавлении Номенкл. категории */
      await dispatch('getMainFields');
      await commit(
        'SET_SELECTED_FILTER',
        Object.keys(
          lodash.groupBy(
            response.filter((el) => el.code !== 'CATEGORY'),
            'code',
          ),
        ),
      );
    } else {
      await commit('SET_SELECTED_FILTER', []);
    }

    if (response.length > 0) return 1;

    return 0;
  },
  async getMainFields({ commit, state }) {
    const codeGroup = lodash.groupBy(state.catalogFilters, 'code');
    const categoryIdList = codeGroup.CATEGORY && codeGroup.CATEGORY[0].valueList;
    if (!categoryIdList || categoryIdList.length === 0) return;
    const response = await api.get('/api/v1/wms/category/fields/list', {
      params: {
        categoryIdList: codeGroup.CATEGORY ? codeGroup.CATEGORY[0].valueList : [''],
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    });

    await commit('SET_FILTER_FIELDS', response);
  },
  async setNewFilter({ commit, dispatch }, payload) {
    const { filter } = payload;
    commit('PUSH_NEW_FILTER', filter);
    if (filter.code === 'CATEGORY') dispatch('getMainFields');
  },
  removeFilter({ commit }, payload) {
    const { filter } = payload;
    commit('REMOVE_FILTER', filter);
  },
  removeFilterFromList({ commit }, payload) {
    const { filter } = payload;
    commit('REMOVE_FILTER_FROM_LIST', filter);
  },
  addSelectedFilter({ commit }, payload) {
    const { filter } = payload;
    commit('PUSH_SELECTED_FILTER', filter);
  },
  removeSelectedFilter({ commit }, payload) {
    const { filter } = payload;
    commit('REMOVE_SELECTED_FILTER', filter);
  },
  // pushSelectedFilter({ commit }, payload) {
  //
  // },
  async saveFilters({ dispatch, state }, payload) {
    const { catalogId } = payload;
    const body = {
      catalogId,
      filterList: state.catalogFilters,
    };
    const res = await Vue.prototype.$catalogService.post('/crm-catalogs/query', body);
    dispatch('getFilters', { catalogId });
    return res;
  },
  async checkFilters({ commit, state }) {
    const body = {
      filterList: state.catalogFilters,
    };
    const response = await Vue.prototype.$catalogService.post('/crm-catalogs/check-query', body);
    const find = state.catalogFilters.find((i) => i.code === 'NOMENCLATURE_ID');
    if (find) {
      commit('SET_SELECTED_PRODUCTS', response);
    }
    commit('SET_PRODUCTS', response);
  },
  async productCheckFilters({ commit }, payload) {
    const body = {
      filterList: [
        { code: 'CATEGORY', valueList: [...payload] },
      ],
    };
    const response = await Vue.prototype.$catalogService.post('/crm-catalogs/check-query', body);
    commit('SET_PRODUCTS', response);
  },
  async productCheckIdFilters({ commit }, payload) {
    const body = {
      filterList: [
        { code: 'NOMENCLATURE_ID', valueList: [...payload] },
      ],
    };
    const response = await Vue.prototype.$catalogService.post('/crm-catalogs/check-query', body);
    commit('SET_PRODUCTS', response);
  },
  // eslint-disable-next-line no-empty-pattern
  async productSaveFilters({ }, { catalogId, payload }) {
    const body = {
      catalogId,
      filterList: [
        {
          code: 'NOMENCLATURE_ID', valueList: [...payload],
        },
      ],
    };
    const res = await Vue.prototype.$catalogService.post('/crm-catalogs/query', body);
    return res;
  },
};
const state = {
  catalogs: [],
  catalogsBase: [],
  catalogsStructure: [],
  filterFields: [],
  catalogFilters: [],
  selectedFilters: [],
  products: [],
  fetchedSelectedProducts: [],
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
