import VueQuillEditor from 'vue-quill-editor';
import ImageResize from 'quill-image-resize';

import 'quill/dist/quill.core.css';

import 'quill/dist/quill.snow.css';

VueQuillEditor.Quill.register('modules/imageResize', ImageResize);
export default VueQuillEditor;

export const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['image'],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ['clean'], // remove formatting button
];
