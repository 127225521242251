<template>
  <v-text-field
    solo
    v-model="value"
  ></v-text-field>
</template>

<script>
export default {
  name: 'cm-text-field',
  props: ['columnName', 'propValue'],
  data() {
    return {
      value: null,
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.$emit('submit', {
            key: this.columnName,
            value: this.value,
          });
        }
      },
    },
    propValue: {
      handler(val) {
        if (val) {
          this.value = this.propValue;
        }
      },
      immediate: true,
    },
  },
  created() {
  },
};
</script>

<style scoped>

</style>
