import axios from 'axios';
import getEnv from '@/utils/env';

let defaultTimeout = 1000 * 60;


const api = axios.create({
  baseURL: `${getEnv('VUE_APP_URL_BACKEND')}`,
  defaultTimeout
});

const dictionary = axios.create({
  baseURL: `${getEnv('VUE_APP_URL_DICTIONARIES')}/dictionaries/api/`,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
  },
  defaultTimeout
});

const billing = axios.create({
  baseURL: `${getEnv('VUE_APP_URL_BILLING')}/billing/api/`,
  timeout: defaultTimeout
});

const image = axios.create({
  baseURL: `${getEnv('VUE_APP_URL_IMAGE')}`,
  timeout: defaultTimeout
});

const routeSheets = axios.create({
  baseURL: `${getEnv('VUE_APP_URL_ROUTESHEET')}/api`,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
  },
  timeout: defaultTimeout
});
const routeWiki = axios.create({
  baseURL: getEnv('VUE_APP_URL_WIKI'),
  responseType: 'json',
  headers: {
    Accept: 'application/json',
  },
  timeout: defaultTimeout
});

const appeals = axios.create({
  baseURL: `${getEnv('VUE_APP_URL_APPEAL')}/api`,
  timeout: defaultTimeout
});

const estimations = axios.create({
  baseURL: `${getEnv('VUE_APP_URL_ESTIMATION')}/api`,
  responseType: 'json',
  timeout: defaultTimeout

});
const news = axios.create({
  baseURL: `${getEnv('VUE_APP_URL_NEWS')}/news/api`,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
  },
  timeout: defaultTimeout
});

const sms = axios.create({
  baseURL: `${getEnv('VUE_APP_URL_NEWS')}/api`,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
  },
  timeout: defaultTimeout
});

const gateway = axios.create({
  baseURL: getEnv('VUE_APP_URL_GATEWAY'),
  timeout: defaultTimeout
});

const reports = axios.create({
  baseURL: `${getEnv('VUE_APP_URL_REPORTS')}`,
  responseType: 'blob',
  timeout: defaultTimeout
});

export {
  api,
  dictionary,
  billing,
  routeSheets,
  image,
  routeWiki,
  appeals,
  estimations,
  news,
  sms,
  gateway,
  reports,
};
