import Vue from 'vue';
import Vuex from 'vuex';
import nomenclatures from '@/store/nomenclatures';
import crm from '@/store/crm';
import entity from '@/store/entity';
import billing from '@/store/billing';
import catalogs from '@/store/catalogs';
import tools from '@/store/tools';
import dictionary from '@/store/dictionary';

Vue.use(Vuex);

export default new Vuex.Store({
  getters: {
    menuList: (state) => state.sideMenuList,
    loading: (state) => state.loading,
    user: (state) => state.user,
    drawer: (state) => state.drawer,
  },
  state: {
    sideMenuList: [],
    loading: false,
    user: {},
    drawer: null,
  },
  mutations: {
    setMenu(state, payload) {
      state.sideMenuList = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setDrawer(state, payload) {
      state.drawer = payload;
    },
  },
  actions: {
    actionSetMenu({ commit, state }, payload) {
      const list = payload.map((i) => ({
        ...i,
        child: i.child.filter((j) => Object.keys(state.user.availablePages).includes(j.path)),
      }));
      commit('setMenu', list);
    },
    actionSetLoading({ commit }, payload) {
      commit('setLoading', payload);
    },
    actionSetUser({ commit }, payload) {
      commit('setUser', payload);
    },
    actionSetDrawer({ commit }, payload) {
      commit('setDrawer', payload);
    },
  },
  modules: {
    nomenclatures,
    crm,
    entity,
    billing,
    catalogs,
    tools,
    dictionary,
  },
});
