import { api } from '@/plugins/api';

const getters = {
  getSuppliers: (state) => state.suppliers,
  ordersStatuses: (state) => state.ordersStatuses,
  feedbacksCount: (state) => state.feedbacksCount,
  appealsCount: (state) => state.appealsCount,
};

const mutations = {
  setSuppliers: (state, payload) => {
    state.suppliers = [...payload];
  },
  setOrdersStatuses: (state, payload) => {
    state.ordersStatuses = payload;
  },
  setFeedbacksCount: (state, count) => {
    state.feedbacksCount = count;
  },
  setAppealsCount: (state, count) => {
    state.appealsCount = count;
  },
};

const actions = {
  actionSetSuppliers: ({ commit }, payload) => {
    commit('setSuppliers', payload);
  },
  // eslint-disable-next-line no-empty-pattern
  actionSearchUsers: async ({ }, payload) => {
    const response = await api.get(`/api/v1/crm/users?search=${payload}`);
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  actionSearchProducts: async ({}, payload) => {
    const response = await api.get(`/api/v1/crm/product/all/search?search=${payload}`);
    return response;
  },
  actionGetOrdersStatuses({ commit }) {
    return new Promise((resolve) => {
      api.get('/api/v1/crm/orders/filters/status')
        .then((res) => {
          commit('setOrdersStatuses', res);
          resolve();
        });
    });
  },
  actionGetFeedbacksCount({ commit }) {
    return new Promise((resolve) => {
      api.get('/api/v1/crm/product/feedback/count-new')
        .then((res) => {
          commit('setFeedbacksCount', res);
          resolve();
        });
    });
  },
  actionGetAppealsCount({ commit }, { vm }) {
    return new Promise((resolve) => {
      vm.$appealService.get('/count-new')
        .then((res) => {
          commit('setAppealsCount', res.content);
          resolve();
        });
    });
  },
};

const state = {
  suppliers: [],
  users: [],
  ordersStatuses: [],
  feedbacksCount: 0,
  appealsCount: 0,
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
