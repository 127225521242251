import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import Keycloak from 'keycloak-js';
import VueLogger from 'vuejs-logger';
import {
  api, dictionary, billing, image, routeSheets, routeWiki,
  appeals, estimations, news, sms, gateway, reports,
} from '@/plugins/api';
import gatewayPlugin from '@/plugins/gatewayService';
import show from '@/plugins/loader';
import VueCropper from 'vue-cropperjs';
import CmImageUploader from '@/components/CmImageUploader.vue';
import ImagePreviewUploader from '@/components/ImagePreviewUploader.vue';
import getEnv from '@/utils/env';
import CmTableData from '@/components/cm-table/cm-table-data.vue';
import { VueMaskDirective } from 'v-mask';
import moment from 'moment';
import ToggleButton from 'vue-js-toggle-button';
import rolePermissions from '@/router/roles';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'cropperjs/dist/cropper.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import i18n from './i18n';
import VueQuillEditor, { toolbarOptions } from './plugins/quill';

const jwt = require('jsonwebtoken');

Vue.use(ToggleButton);
Vue.use(VueQuillEditor, {
  placeholder: '',
  modules: {
    imageResize: {},
    toolbar: toolbarOptions,
  },
});
Vue.config.productionTip = false;
Vue.use(VueLogger);
Vue.use({
  i18n,
  render: (h) => h(App),
});
Vue.use(VueToast, {
  position: 'top-right',
});
Vue.component('cm-table-data', CmTableData);
Vue.component('CmImageUploader', CmImageUploader);
Vue.component('ImagePreviewUploader', ImagePreviewUploader);
Vue.component('VueCropper', VueCropper);
Vue.directive('mask', VueMaskDirective);
Vue.use(gatewayPlugin);

const initOptions = {
  url: `${getEnv('VUE_APP_URL_KEYCLOAK')}/auth`, realm: 'clever-market', clientId: 'clever-crm-vue', onLoad: 'login-required',

};
const keycloak = Keycloak(initOptions);
Vue.prototype.$keycloak = keycloak;
keycloak.onTokenExpired = () => {
  keycloak.updateToken(50).then(() => {
    Vue.$log.info('token refreshed');
  }).catch((error) => {
    Vue.$log.warn('error on refresh', error);
  });
};

keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    Vue.$log.info('Authenticated');

    api.defaults.timeout = 30000;
    api.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = `Bearer ${keycloak.token}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    api.interceptors.response.use(
      (response) => response.data,
      (error) => {
        Vue.$toast.open({
          message: error.response?.data?.error ? i18n.t(error.response?.data?.error) : 'Произошла ошибка',
          type: 'error',
        });
        return Promise.reject(error);
      },
    );

    billing.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = `Bearer ${keycloak.token}`;
        return config;
      },
      (error) => Promise.reject(error),
    );
    billing.interceptors.response.use(
      (response) => response.data,
      (error) => {
        Vue.$toast.open({
          message: error.response?.data?.error ? i18n.t(error.response?.data?.error) : 'Произошла ошибка',
          type: 'error',
        });
        return Promise.reject(error);
      },
    );

    dictionary.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = `Bearer ${keycloak.token}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    dictionary.interceptors.response.use(
      (response) => response.data,
      (error) => error.response,
    );

    image.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = `Bearer ${keycloak.token}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    image.interceptors.response.use(
      (response) => response.data,
      (error) => error.response,
    );

    routeSheets.defaults.timeout = 30000;
    routeSheets.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = `Bearer ${keycloak.token}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    routeSheets.interceptors.response.use(
      (response) => response.data,
      (error) => {
        if (error.response.data.Errors) {
          error.response.data.Errors.forEach((e) => {
            Vue.$toast.open({
              message: e || 'Произошла ошибка',
              type: 'error',
            });
          });
        }
        return Promise.reject(error);
      },
    );
    routeWiki.defaults.timeout = 30000;
    routeWiki.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = `Bearer ${keycloak.token}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    routeWiki.interceptors.response.use(
      (response) => response.data,
      (error) => {
        Vue.$toast.open({
          message: error.response?.data?.error ? i18n.t(error.response?.data?.error) : 'Произошла ошибка',
          type: 'error',
        });
        return Promise.reject(error);
      },
    );

    appeals.defaults.timeout = 30000;
    appeals.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = `Bearer ${keycloak.token}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    appeals.interceptors.response.use(
      (response) => response.data,
      (error) => {
        if (error.response.data.Errors) {
          error.response.data.Errors.forEach((e) => {
            Vue.$toast.open({
              message: e || 'Произошла ошибка',
              type: 'error',
            });
          });
        }
        return Promise.reject(error);
      },
    );

    estimations.defaults.timeout = 30000;
    estimations.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = `Bearer ${keycloak.token}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    estimations.interceptors.response.use(
      (response) => response.data,
      (error) => {
        if (error.response.data.Errors) {
          error.response.data.Errors.forEach((e) => {
            Vue.$toast.open({
              message: e || 'Произошла ошибка',
              type: 'error',
            });
          });
        }
        return Promise.reject(error);
      },
    );

    news.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = `Bearer ${keycloak.token}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    news.interceptors.response.use(
      (response) => response.data,
      (error) => {
        if (error.response.data.Errors) {
          error.response.data.Errors.forEach((e) => {
            Vue.$toast.open({
              message: e || 'Произошла ошибка',
              type: 'error',
            });
          });
        }
        return Promise.reject(error);
      },
    );
    sms.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = `Bearer ${keycloak.token}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    sms.interceptors.response.use(
      (response) => response.data,
      (error) => {
        if (error.response.data.Errors) {
          error.response.data.Errors.forEach((e) => {
            Vue.$toast.open({
              message: e || 'Произошла ошибка',
              type: 'error',
            });
          });
        }
        return Promise.reject(error);
      },
    );
    gateway.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = `Bearer ${keycloak.token}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    gateway.interceptors.response.use(
      (response) => response.data,
      (error) => {
        const { data } = error.response;
        if (data) {
          if (typeof data === 'string') {
            Vue.$toast.open({
              message: data || 'Произошла ошибка',
              type: 'error',
            });
          } else if (data.error && typeof data.error === 'string') {
            Vue.$toast.open({
              message: data.error || 'Произошла ошибка',
              type: 'error',
            });
          } else if (data.Errors && data.Errors.length) {
            error.response.data.Errors.forEach((e) => {
              Vue.$toast.open({
                message: e || 'Произошла ошибка',
                type: 'error',
              });
            });
          }
        }
        return Promise.reject(error);
      },
    );

    reports.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = `Bearer ${keycloak.token}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    reports.interceptors.response.use(
      (response) => response.data,
      (error) => {
        if (error.response.data.Errors) {
          error.response.data.Errors.forEach((e) => {
            Vue.$toast.open({
              message: e || 'Произошла ошибка',
              type: 'error',
            });
          });
        }
        return Promise.reject(error);
      },
    );
    Vue.prototype.$loading = show;
    Vue.prototype.$moment = moment;

    keycloak.loadUserInfo().then(async (data) => {
      const decodedToken = jwt.decode(keycloak.token);
      const { roles } = decodedToken.realm_access;
      const availableRoles = rolePermissions.filter((i) => roles.includes(i.name));
      const availablePages = {};
      availableRoles.forEach((i) => {
        Object.entries(i.pages).forEach(([key, value]) => {
          if (availablePages[key]) {
            Object.entries(availablePages[key]).forEach(([pKey, pValue]) => {
              if (!pValue && value) {
                availablePages[key][pKey] = value;
              }
            });
          } else {
            availablePages[key] = value;
          }
        });
      });
      await store.dispatch('actionSetUser',
        {
          ...data, roles, permissions: availableRoles, availablePages,
        });
      Vue.prototype.$api = api;
      Vue.prototype.$billing = billing;
      Vue.prototype.$dictionary = dictionary;
      Vue.prototype.$image = image;
      Vue.prototype.$routeSheets = routeSheets;
      Vue.prototype.$routeWiki = routeWiki;
      Vue.prototype.$appeals = appeals;
      Vue.prototype.$estimations = estimations;
      Vue.prototype.$news = news;
      Vue.prototype.$sms = sms;
      Vue.prototype.$gateway = gateway;
      Vue.prototype.$reports = reports;

      new Vue({
        i18n,
        router,
        store,
        vuetify,
        render: (h) => h(App, { props: { keycloak } }),
      }).$mount('#app');
    });
  }
}).catch(() => {
  Vue.$log.error('Authenticated Failed');
});
