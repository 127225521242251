const Nomenclatures = () => import('@/views/wms/nomenclatures/index.vue');

const Warehouse = () => import('@/views/wms/warehouses/index.vue');
const Reception = () => import('@/views/wms/reception/index.vue');
// const Operations = () => import('@/views/wms/operations/index.vue');
const Product = () => import('@/views/wms/nomenclatures/product/index.vue');
const SupplierDeliveries = () => import('@/views/wms/operations/supplier-deliveries/index.vue');
const DeliveryCard = () => import('@/views/wms/operations/supplier-deliveries/SupplyCard.vue');
const Return = () => import('@/views/wms/operations/returns/index.vue');
const Balance = () => import('@/views/wms/balance/index.vue');
const Delivery = () => import('@/views/wms/operations/delivery/index.vue');
const Home = () => import('@/views/wms/index.vue');

// const Acceptance = () => import('@/views/wms/operations/acceptance/index.vue');
// const Posting = () => import('@/views/wms/operations/posting/index.vue');
// const Moving = () => import('@/views/wms/operations/moving/index.vue');
// const Writeoff = () => import('@/views/wms/operations/writeoff/index.vue');
// const Inventory = () => import('@/views/wms/operations/inventory/index.vue');
module.exports = [
  {
    path: 'nomenclature',
    name: 'nomenclature',
    component: Nomenclatures,
    meta: {
      title: 'Номенклатура',
      parent: 'nomenclature',
    },
  },
  {
    path: 'nomenclature/add-product',
    name: 'nomenclature/add-product',
    component: Product,
    meta: {
      title: 'Добавить продукт',
      parent: 'nomenclature',
    },
  },
  {
    path: 'warehouse',
    name: 'warehouse',
    component: Warehouse,
    meta: {
      title: 'Склад',
    },
  },
  {
    path: 'operations',
    name: 'operations',
    // redirect: { name: 'supplier-deliveries' },
    // redirect: (route, router) => {
    //   console.log(route, router);
    // },
    meta: {
      title: 'Операции',
      parent: 'operations',
    },
  },
  {
    path: 'operations/return',
    name: 'return',
    component: Return,
    meta: {
      title: 'Операции',
      parent: 'operations',
    },
  },
  {
    path: 'reception',
    name: 'reception',
    component: Reception,
    meta: {
      title: 'Акт приемки',
    },
  },
  {
    path: 'operations/supplier-deliveries',
    name: 'supplier-deliveries',
    component: SupplierDeliveries,
    meta: {
      title: 'Поставки',
      parent: 'operations',
    },
  },
  {
    path: 'operations/supplier-delivery/:id',
    name: 'supplier-delivery',
    component: DeliveryCard,
    meta: {
      title: 'Поставка',
      parent: 'operations',
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Clever WMS',
    },
  },
  {
    path: 'balance',
    name: 'balance',
    component: Balance,
    meta: {
      title: 'Остатки',
      parent: 'balance',
    },
  },
  {
    path: 'operations/delivery',
    name: 'delivery',
    component: Delivery,
    meta: {
      title: 'Операции',
      parent: 'operations',
    },
  },
  // {
  //   path: 'operations/acceptance',
  //   name: 'acceptance',
  //   component: Acceptance,
  //   meta: {
  //     title: 'Приемка',
  //     parent: 'operations',
  //   },
  // },
  // {
  //   path: 'operations/posting',
  //   name: 'posting',
  //   component: Posting,
  //   meta: {
  //     title: 'Поставка',
  //     parent: 'operations',
  //   },
  // },
  // {
  //   path: 'operations/moving',
  //   name: 'moving',
  //   component: Moving,
  //   meta: {
  //     title: 'Поставка',
  //     parent: 'operations',
  //   },
  // },
  // {
  //   path: 'operations/writeoff',
  //   name: 'writeoff',
  //   component: Writeoff,
  //   meta: {
  //     title: 'Поставка',
  //     parent: 'operations',
  //   },
  // },
  // {
  //   path: 'operations/inventory',
  //   name: 'inventory',
  //   component: Inventory,
  //   meta: {
  //     title: 'Поставка',
  //     parent: 'operations',
  //   },
  // },
];
