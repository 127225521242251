<template>
  <div class="home">
    <v-img alt="Clever" src="../assets/welcome.jpg" contain/>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  created() {
    this.redirect();
  },
  methods: {
    redirect() {
      const { availablePages } = this.user;
      if (Object.keys(availablePages).includes('CRM')) {
        this.$router.push('/crm');
      } else if (Object.keys(availablePages).includes('WMS')) {
        this.$router.push('/wms');
      }
    },
  },
};
</script>

<style>
.home {
  max-width: 80vw;
}
</style>
