<template>
  <v-dialog v-model="dialogVisible"
            @click:outside="$emit('closed')"
            @keypress.esc.prevent="$emit('closed')"
            :scrollable="false"
            max-width="80vw"

  >
    <v-card
      max-height="80vh"
      max-width="80vw"
      style="transform: translate(-50%); left: 50%;"
    >
      <v-card-title>
        Поместите картинку в рамки
      </v-card-title>
      <v-card-text>
        <VueCropper
          ref="cropper"
          :src="image.url"
          alt="Source Image"
          :initialAspectRatio="aspectRatio"
          :aspectRatio="aspectRatio"
          :cropBoxResizable="true"
          :dragMode="'move'"
          :modal="false"
          :background="false"

        />
      </v-card-text>
      <v-card-actions style="background-color: transparent">
        <v-row justify="end">
          <v-btn width="20%" color="green" dark
                 style="position:absolute; bottom: 30px; right: 50px;"
                 @click="cropImage"
          >Сохранить</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

export default {
  name: 'ImageCropper',
  props: {
    aspectRatio: {
      default: 1 / 1,
      type: Number,
    },
    image: {
      type: Object,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      cropImg: null,
      dialogVisible: false,
    };
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        this.dialogVisible = val;
      },
    },
  },
  methods: {
    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$emit('handleCroppedImage', {
        ...this.image,
        url: this.cropImg,
      });
    },
  },
};
</script>

<style scoped>

</style>
