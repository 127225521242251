<template>
  <v-app-bar app class="top-bar" clipped-left dark height="60px" color="#311b57">
    <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile" @click.stop="showSideBar">
    </v-app-bar-nav-icon>
    <router-link :to="{ name: 'Home' }">
      <v-img
        :src="require('@/assets/new_logo.svg')"
        transition="scale-transition"
        contain
        width="144px"
        height="34px"
      />
    </router-link>
    <v-row>
      <v-col align-self="center" class="flex-nowrap">
        <v-row class="ml-8">
          <v-col cols="auto" v-if="showCRMLink" class="pa-1">
            <v-btn v-bind="attrs" to="/crm">
              CRM
            </v-btn>
          </v-col>
          <v-col cols="auto" v-if="showWMSLink" class="pa-1">
            <v-btn v-bind="attrs" to="/wms">
              WMS
            </v-btn>
          </v-col>
          <v-col cols="auto" v-if="showDictionariesLink" class="pa-1">
            <v-btn v-bind="attrs" to="/dictionaries">
              Справочники
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="auto"
        align-self="center"
        v-if="!$vuetify.breakpoint.xs"
      >
        <v-row dense justify="end">
          <v-menu offset-y right
                  open-on-hover content-class="top-bar__exit"
                  close-delay="500">
            <template v-slot:activator="{ attrs, on }">
              <div class="d-flex py-2" v-bind="attrs" v-on="on">
                <v-avatar color="white lighten-2">
                  <span style="color: #737373">
                    {{ initials }}
                  </span>
                </v-avatar>
                <div class="mx-4">
                  <div class="top-bar__profile">{{ user.name }}</div>
                  <div>{{ user.email }}</div>
                </div>
              </div>
            </template>
            <v-list>
              <v-list-item link @click="logout">
                <v-list-item-title>Выход</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  name: 'TopBarMenu',
  data() {
    return {
      attrs: {
        activeClass: 'active-menu-button',
        height: '40px',
        rounded: true,
        large: true,
        dark: true,
        text: true,
        link: true,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    initials() {
      const keys = Object.keys(this.user);
      let name = '';
      if (keys.length > 0) {
        if (keys.includes('given_name')) {
          name += this.user.given_name.substring(0, 1);
        }

        if (keys.includes('family_name')) {
          name += this.user.family_name.substring(0, 1);
        }
        return name.toUpperCase();
      }
      return '';
    },
    showCRMLink() {
      return Object.keys(this.user.availablePages).includes('CRM');
    },
    showWMSLink() {
      return Object.keys(this.user.availablePages).includes('WMS');
    },
    showDictionariesLink() {
      return Object.keys(this.user.availablePages).includes('DICTIONARIES');
    },
  },
  methods: {
    showSideBar() {
      this.$store.dispatch('actionSetDrawer', true);
    },
    logout() {
      this.$keycloak.logout();
    },
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161) !important;

  &__profile {
    overflow: hidden;
    white-space: nowrap;
  }
  &__exit {
    top: 75px!important;
  }
}

.active-menu-button {
  font-weight: 900;
}
</style>
