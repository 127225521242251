<template>
  <v-navigation-drawer
    :permanent="!$vuetify.breakpoint.mobile"
    app
    clipped
    v-model="drawer"
    width="256"
    color="#E9E9E9"
  >
    <v-list class="mt-8">
      <v-list-item
        v-for="item in menuList"
        :key="item.label"
        link
        :to="{ name: item.path }"
      >
        <v-list-item-icon class="ml-3">
          <v-img
            v-if="item.icon"
            :src="require(`@/assets/icons/${item.icon}`)"
            transition="scale-transition"
            contain
            height="16"
            width="16"
          />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="text-wrap" v-text="item.label"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon v-if="ordersStatuses.length && item.path.includes('orders')">
          <v-badge
            color="green"
            :value="newOrdersCount"
            :content="newOrdersCount"
            inline
          >
          </v-badge>
        </v-list-item-icon>
        <v-list-item-icon v-else-if="item.path.includes('feedbacks')">
          <v-badge
            color="green"
            :value="feedbacksCount"
            :content="feedbacksCount"
            inline
          >
          </v-badge>
        </v-list-item-icon>
        <v-list-item-icon v-else-if="item.path.includes('appeals')">
          <v-badge
            color="green"
            :value="appealsCount"
            :content="appealsCount"
            inline
          >
          </v-badge>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

import { mapGetters } from 'vuex';
import { throttle } from 'lodash';

export default {
  name: 'SidebarMenu',
  data() {
    return {
      right: null,
    };
  },
  created() {
    /** 5 minute delay request */
    this.throttledWatch = throttle(() => {
      this.$store.dispatch('crm/actionGetOrdersStatuses');
      this.$store.dispatch('crm/actionGetFeedbacksCount');
      this.$store.dispatch('crm/actionGetAppealsCount', { vm: this });
    }, 60e3 * 5);
  },
  beforeUnmount() {
    this.throttledWatch.cancel();
  },
  computed: {
    ...mapGetters('crm', ['ordersStatuses', 'feedbacksCount', 'appealsCount']),
    menuList() {
      return this.$store.getters.menuList
        .filter((i) => Object.keys(this.availablePages).includes(i.path));
    },
    availablePages() {
      return this.$store.getters.user.availablePages;
    },
    drawer: {
      get() {
        return this.$store.getters.drawer;
      },
      set(newValue) {
        this.$store.dispatch('actionSetDrawer', newValue);
      },
    },
    newOrdersCount() {
      const newOrders = this.ordersStatuses.find((n) => n.status === 'NEW');
      return newOrders ? newOrders.count : 0;
    },
  },
  watch: {
    $route(to) {
      if (to.path.includes('crm') && !to.path.includes('crm/orders')) {
        this.throttledWatch();
      }
    },
    '$route.name': {
      handler(val) {
        if (val === 'Home') {
          // this.$store.dispatch('actionSetMenu', []);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
/** overriding list-item styles */
.v-list-item--active {
  border-left: 4px solid #005332;
  background-color: rgba(255, 255, 255, 0.4);
  padding-left: 12px;
  color: #005332;
  .v-image {
    filter: invert(25%) sepia(17%) saturate(2782%)
    hue-rotate(113deg) brightness(50%) contrast(102%);
  }
}
.theme--light.v-list-item--active::before {
  opacity: 0;
}
.v-list-item__icon {
  align-self: center;
}
.v-application--is-ltr .v-list-item__icon:first-child {
  margin: 16px 12px 16px 48px
}
</style>
