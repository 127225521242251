const Regions = () => import('@/views/dictionaries/regions/index.vue');
const Currency = () => import('@/views/dictionaries/currency/index.vue');
const Colours = () => import('@/views/dictionaries/colours/index.vue');
const Warehouses = () => import('@/views/dictionaries/warehouses/index.vue');
const Measures = () => import('@/views/dictionaries/measures/index.vue');
const Home = () => import('@/views/dictionaries/index.vue');
const Errors = () => import('@/views/dictionaries/errors/index.vue');

const Status = () => import('@/views/dictionaries/status/index.vue');

const dictionariesRoutes = [
  {
    path: 'region',
    name: 'region',
    component: Regions,
    meta: {
      title: 'Регионы',
      parent: 'region',
    },
  },
  {
    path: 'warehouses',
    name: 'warehouses',
    component: Warehouses,
    meta: {
      title: 'Склады',
      parent: 'warehouses',
    },
  },
  {
    path: 'measures',
    name: 'measures',
    component: Measures,
    meta: {
      title: 'Ед.измерений',
      parent: 'measures',
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Справочники',
    },
  },
  {
    path: 'currency',
    name: 'currency',
    component: Currency,
    meta: {
      title: 'Валюта',
      parent: 'currency',
    },
  },
  {
    path: 'colours',
    name: 'colours',
    component: Colours,
    meta: {
      title: 'Цвета',
      parent: 'colours',
    },
  },
  {
    path: 'status',
    name: 'status',
    component: Status,
    meta: {
      title: 'Статусы',
      parent: 'status',
    },
  },
  {
    path: 'errors',
    name: 'errors',
    component: Errors,
    meta: {
      title: 'Ошибки',
      parent: 'errors',
    },
  },
];

export default dictionariesRoutes;
