<template>
  <v-container fluid style="position: relative">
    <input
      type="file"
      accept="image/png, image/gif, image/jpeg"
      @change="onFileChange" />
    <div id="preview">
      <img v-if="url" :src="url" />
      <div v-if="url && loading" class="overlay">
        <div class="text">{{ loadingTitle }}</div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'ImagePreviewUploader',
  props: {
    imageURL: {
      type: String,
      default: '',
    },
    /** if image is in list (array) */
    imageItem: {
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingTitle: {
      type: String,
      default: 'Загружается...',
    },
  },
  data() {
    return {
      url: this.imageURL,
    };
  },
  methods: {
    onFileChange(e) {
      if (!e.target.files.length) return;
      const file = e.target.files[0];
      this.url = window.URL.createObjectURL(file);
      this.$emit('onImageSelect', { url: this.url, file, imageItem: this.imageItem });
    },
  },
};
</script>

<style scoped>

#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#preview img {
  width: 300px;
  height: auto;
  /*max-width: 100%;
  max-height: 250px;*/
}

#preview .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  /*opacity: 0;*/
  transition: .5s ease;
  background-color: rgba(28, 14, 53, 0.52);
}
.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
