<template>
  <v-snackbar top right :color="snackbar.type === 'error' ? 'orange' : 'green'" v-model="show"
              :multi-line="multiLine" content-class="snack"
              :light="snackbar.type === 'error'"
              app
              transition="scroll-x-reverse-transition" rounded>
      {{ snackbar.message }}
      <template #action>
        <v-btn text @click.native="handleClose"
               icon large class="close-button">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
  </v-snackbar>
</template>

<script>

export default {
  name: 'Snackbar',
  data() {
    return {
      multiLine: false,
    };
  },
  computed: {
    snackbar: {
      get() {
        return this.$store.getters['tools/snackbar'];
      },
      set(val) {
        this.$store.dispatch('tools/setSnackbar', val);
      },
    },
    show: {
      get() {
        return !!this.snackbar.message;
      },
      set() {
        this.snackbar.message = '';
        this.snackbar.type = '';
      },
    },
  },
  methods: {
    handleClose() {
      this.snackbar = {};
    },
  },
};
</script>

<style lang="scss">
.snack {
  width: 90%;
  word-break: break-word;
  position: relative;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
}

</style>
