<template>
  <v-container fluid>
    <ImageCropper :image="cropImg"
                  v-if="visible"
                  :visible="visible"
                  @handleCroppedImage="handleCropImage"
                  @closed="closeImageCropDialog"
    />
    <div class="upload-button" @click="showUpload" v-if="!hideButton">
      <label>
        Выберите файл
      </label>
    </div>
    <v-file-input
      style="display: none"
      class="upload"
      :id="id"
      clearable
      outlined
      placeholder=""
      prepend-icon=""
      height="100"
      hide-details
      label="Выбрать файл"
      v-model="file"
      @change="addFile(file)"
      accept="image/png, image/gif, image/jpeg"
    />
  </v-container>
</template>

<script>
import ImageCropper from '@/components/ImageCropper.vue';

export default {
  name: 'CmImageUploader',
  props: {
    hideButton: {
      default: false,
      type: Boolean,
    },
    id: {
      type: String,
      default: 'file-input',
    },
    noCrop: {
      type: Boolean,
      default: false,
    },
  },

  components: { ImageCropper },
  data() {
    return {
      file: null,
      cropImg: null,
      visible: false,
    };
  },
  methods: {
    async addFile(file) {
      const url = await this.readUploadedFileAsUrl(file);
      this.cropImg = {
        url,
        file,
      };
      if (this.noCrop) {
        this.$emit('set', this.cropImg);
        this.closeImageCropDialog();
      } else {
        this.visible = true;
      }
    },
    readUploadedFileAsUrl(inputFile) {
      const temporaryFileReader = new FileReader();

      return new Promise((resolve) => {
        temporaryFileReader.onload = () => {
          resolve(temporaryFileReader.result);
        };
        temporaryFileReader.readAsDataURL(inputFile);
      });
    },
    handleCropImage(image) {
      this.closeImageCropDialog();
      this.$emit('set', { ...image });
    },
    closeImageCropDialog() {
      this.file = null;
      this.visible = false;
      this.cropImg = null;
    },
    showUpload() {
      document.querySelector('#file-input').click();
    },
  },
};
</script>

<style scoped lang="scss">
.upload-button {
  border: 1px dashed #9A9D9C;
  width: 400px;
  height: 100px;
  border-radius: 5px;
  background-color: #FFFFFF;
  position: relative;
  cursor: pointer;

  margin-bottom: 20px;
  label {
    position: absolute;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px !important;
    /* identical to box height */

    color: #0B583F !important;

  }
}
.upload-button:hover {
  transition: border-width 10ms ease-in-out;
  border-width: 3px;
}

</style>
