import { api } from '@/plugins/api';

const getters = {
};

const mutations = {
  // setSuppliers: (state, payload) => {
  //   state.suppliers = [...payload];
  // },
};

const actions = {
  async getAllSettings({ dispatch }) {
    const arr = [1, 2, 3, 4, 5, 6];
    const resultPromise = [];
    for (let i = 0; i < arr.length; i += 1) {
      const response = dispatch('getSetting', arr[i]);
      resultPromise.push(response);
    }
    await Promise.all(resultPromise);
  },
  getSetting(payload) {
    return api.get(`/api/v1/crm/billing/${payload}`);
  },
  setSetting({ dispatch }, payload) {
    const { comissionType, comissionValue, comissionMeasureType } = payload;
    const response = api.post(`/api/v1/crm/billing/${comissionType}`, { comissionMeasureType, comissionValue });
    if (response.data) {
      dispatch('getSetting', comissionType);
    }
  },
};

const state = {
  suppliers: [],
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
