import Vue from 'vue';
import { gateway } from '@/plugins/api';

const gatewayPlugin = {
  install: () => {
    if (!gateway) {
      throw new Error('Please provide gateway api.');
    }

    const methods = (servicePath) => ({
      get(url = '', config = {}) {
        return gateway.get(`${servicePath}${url}`, config);
      },
      delete(url = '', config = {}) {
        return gateway.delete(`${servicePath}${url}`, config);
      },
      post(url = '', data = {}, config = {}) {
        return gateway.post(`${servicePath}${url}`, data, config);
      },
      put(url = '', data = {}, config = {}) {
        return gateway.put(`${servicePath}${url}`, data, config);
      },
    });

    Vue.prototype.$javaService = methods('/java-service/api/v1');
    Vue.prototype.$newCrmService = methods('/newcrm-service/api/v1');
    Vue.prototype.$wikiService = methods('/wiki-service/api');
    Vue.prototype.$dictionaryService = methods('/dictionaries-service/dictionaries/api');
    Vue.prototype.$searchService = methods('/search-service/api/v1');
    Vue.prototype.$catalogService = methods('/catalog-service/api/v1');
    Vue.prototype.$newsService = methods('/news-service-v2/api/v1');
    Vue.prototype.$ratingService = methods('/rating-service');
    Vue.prototype.$reportService = methods('/report-service/api/v1');
    Vue.prototype.$appealService = methods('/appeal-service/api/appeal');
  },
};
export default gatewayPlugin;
