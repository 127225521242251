<template>
  <v-dialog v-model="confirm.show" :persistent="confirm.persistent" scrollable="scrollable"
            :max-width="500">
    <v-card>
      <v-card-title >
        <div class="confirm-title">
          <span class="headline ">{{ confirm.title }}</span>
          <v-btn icon="icon" @click.native="hideConfirm" class="close-button">
            <v-icon>mdi-close</v-icon>
          </v-btn>

        </div>
      </v-card-title>
      <v-divider/>
      <v-card-text class="mt-2">
        <span>
          {{ confirm.text }}
        </span>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" text @click.native="reject">{{ confirm.rejectText }}</v-btn>
        <v-btn color="primary" @click.native="resolve">{{ confirm.resolveText }}</v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Confirm',
  computed: {
    ...mapGetters('tools', {
      confirm: 'confirm',
    }),
  },

  methods: {
    ...mapActions('tools', {
      hideConfirm: 'hideConfirm',
    }),

    resolve() {
      this.confirm.deferred.resolve();
      this.hideConfirm();
    },

    reject() {
      this.confirm.deferred.reject();
      this.hideConfirm();
    },
  },

};
</script>

<style scoped lang="scss">
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.confirm-title {
  height: 2rem;
}
</style>
