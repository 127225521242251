<template>
  <v-data-table
    :headers="columns"
    :items="tableData"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>{{config.title.main}}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-dialog
          v-model="dialog"
          max-width="1000px"
          @click:outside="close"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="config.permissions.create"
              color="#9DC033"
              dark
              v-bind="attrs"
              v-on="on"
              @click="dialogTitle = config.title.create"
            >
              Добавить
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <v-card
            color="#F7F7F6"
          >
            <v-card-title class="mb-2">
              <div class="d-flex">
                <v-btn
                    color="#9DC033"
                    @click="save"
                    width="200"
                    height="55"
                    dark
                >
                  Сохранить
                </v-btn>
                <v-btn
                    class="ml-3"
                    color="#CFD0C9"
                    @click="close"
                    width="200"
                    height="55"
                    dark
                >
                  Закрыть
                </v-btn>
              </div>
            </v-card-title>
            <v-card-text class="pt-5">
              <div class="row--dense" style="border: 1px solid;">
                <div class="d-flex justify-space-between align-center"
                     style="border-bottom: 1px solid; height: 55px; max-height: 55px"
                >
                  <div class="d-flex col-4 justify-start">
                    <h1 class="pr-10">{{ dialogTitle }}</h1>
                    <div v-if="editId">#{{item.id}}</div>
                  </div>
                  <div v-if="editId" class="d-flex col-8 justify-end">
                    <div class="d-flex flex-column mr-5" >
                      <span>Автор: {{ userCreated ? userCreated : '' }} </span>
                      <span>Дата и время:
                        {{ (item && item.createdDate) ? dateFormatter(item.createdDate) : ''}}
                      </span>
                    </div>
                    <div class="d-flex flex-column">
                      <span>Редактор: {{ userModified ? userModified : ''}} </span>
                      <span>Дата и время:
                  {{ (item && item.lastModifiedDate) ? dateFormatter(item.lastModifiedDate) : ''}}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <v-form ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                    <div>
                      <div class="pa-5">
                        <div class="item"
                             v-for="col in columns.filter(i => i.value !== 'actions')"
                             :key="col.value"
                        >
                          <span class="col-xs-12 col-sm-5">
                            {{ col.text }}
                          </span>
                          <component :is="col.component" class="col-xs-12 col-sm-7"
                                     :column-name="col.value" @submit="onSubmit"
                                     :propValue="item[col.value]"
                                     :store="col.store"
                                     :rules="col.rules"
                          />
                        </div>
                      </div>
                    </div>
                  </v-form>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <div class="d-flex">
            <v-btn
              color="#9DC033"
              @click="deleteItemConfirm"
              width="200"
              height="55"
              dark
            >
              Сохранить
            </v-btn>
            <v-btn
              class="ml-3"
              color="#CFD0C9"
              @click="closeDelete"
              width="200"
              height="55"
              dark
            >
              Закрыть
            </v-btn>
          </div>
          <v-card>
            <v-card-title class="headline">Вы действительно хотите удалить?</v-card-title>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template
      v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        v-if="config.permissions.edit"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        v-if="config.permissions.delete"
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';
import * as components from '@/components/cm-table/form/index';

export default {
  name: 'cm-table-data',
  props: {
    pheaders: {
      default: () => [],
    },
    pcolumns: {
      default: () => [],
    },
    pconfig: {
      default: () => [],
    },
  },
  components: {
    ...components,
  },
  data() {
    return {
      headers: this.pheaders,
      columns: this.pcolumns,
      config: this.pconfig,
      dialogTitle: null,
      tableData: [],
      valid: true,
      userCreated: null,
      userModified: null,
      item: {},
      editId: null,
      dialog: false,
      dialogDelete: false,
    };
  },

  created() {
    this.initialize();
  },
  watch: {
    editId: {
      handler(val) {
        if (val) {
          this.item = { ...this.tableData.find((i) => i.id === val) };
          this.setUserInformation();
        }
      },
    },
  },
  methods: {
    initialize() {
      this.$api[this.config.endpoints.get.method](this.config.endpoints.get.url)
        .then((response) => {
          this.tableData = response.content ? [...response.content] : [...response];
        });
    },

    editItem(item) {
      this.dialogTitle = this.config.title.edit;
      this.editId = item.id;
      this.dialog = true;
    },

    deleteItem(item) {
      this.dialogTitle = this.config.title.delete;
      this.editId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$api[this.config.endpoints.delete.method](this.config.endpoints.delete.url + this.editId)
        .then(() => {
          this.initialize();
        }).finally(() => {
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.reset();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.reset();
    },

    save() {
      this.$loading(true);
      if (this.validate()) {
        const { method, url } = this.config.endpoints[this.editId ? 'edit' : 'create'];
        this.$api[method](url, { ...this.item }).then(() => {
          this.reset();
          this.close();
          this.initialize();
        }).catch((e) => {
          this.$log.warn(e);
        }).finally(() => {
          this.$loading(false);
        });
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.editId = null;
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    dateFormatter(val) {
      return moment(val).format('DD-MM-YYYY HH:mm');
    },
    getUserInfo(id) {
      return this.$api.get(`/api/v1/crm/users/${id}`);
    },
    setUserInformation() {
      if (this.item.createdBy) {
        this.getUserInfo(this.item.createdBy).then((response) => {
          this.$nextTick(() => {
            this.userCreated = `${response.firstName} ${response.lastName}`;
          });
        });
      }
      if (this.item.lastModifiedBy) {
        this.getUserInfo(this.item.lastModifiedBy).then((response) => {
          this.$nextTick(() => {
            this.userModified = `${response.firstName} ${response.lastName}`;
          });
        });
      }
    },
    onSubmit({ key, value }) {
      this.item[key] = value;
    },
  },
};
</script>

<style scoped>

.item {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
}

</style>
