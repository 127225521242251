<template>
  <v-app>
    <top-bar-menu/>
    <v-main>
      <sidebar-menu/>
<!--      <v-container fluid class="main-container" >-->
<!--          <v-row dense>-->
<!--            <v-col cols="12">-->
              <router-view/>
<!--            </v-col>-->
<!--          </v-row>-->
<!--      </v-container>-->
    </v-main>
    <loading/>
    <Confirm/>
    <Snackbar/>
  </v-app>
</template>

<script>

import SidebarMenu from '@/components/SidebarMenu.vue';
import TopBarMenu from '@/components/TopBarMenu.vue';
import Loading from '@/components/Loading.vue';
import Confirm from '@/components/Confirm.vue';
import Snackbar from '@/components/Snackbar.vue';

export default {
  name: 'App',

  components: {
    Snackbar,
    Confirm,
    Loading,
    TopBarMenu,
    SidebarMenu,
  },

  data: () => ({
    //
  }),
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Clever';
      },
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: 'Montserrat', sans-serif;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}
.v-btn__content {
  text-transform: none;
}
.crm-page-height {
  position: relative;
  min-height: calc(100vh - 190px);
  height: calc(100vh - 190px);
  display: flex;
}
.vue-js-switch .v-switch-label.v-right {
  color: #737373 !important;
}
.v-snack__content{
  padding-right: 40px;
}
</style>
