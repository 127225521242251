<template>
  <v-select
    solo
    v-model="value"
    :items="items"
    item-text="name"
    item-value="id"
    :rules="rules"
  ></v-select>
</template>

<script>
export default {
  name: 'cm-list',
  props: ['store', 'columnName', 'rules', 'propValue'],
  data() {
    return {
      value: null,
      items: [],
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.$emit('submit', {
            key: this.columnName,
            value: this.value,
          });
        }
      },
    },
    propValue: {
      handler(val) {
        if (val) {
          this.value = this.propValue;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.items = this.$store.getters['entity/getEntity'](this.store);
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
