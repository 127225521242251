import CatalogOrder from '@/views/crm/catalogOrder/index.vue';

const Sales = () => import('@/views/crm/sales/index.vue');
const Suppliers = () => import('@/views/crm/suppliers/index.vue');
const Appeals = () => import('@/views/crm/appeals/index.vue');
const Procurements = () => import('@/views/crm/procurements/index.vue');
const Clients = () => import('@/views/crm/clients/index.vue');
const Refunds = () => import('@/views/crm/clients/refunds.vue');
const Calls = () => import('@/views/crm/calls/index.vue');
const SuppliersRequests = () => import('@/views/crm/supplier-requests/index.vue');
const Orders = () => import('@/views/crm/orders/index.vue');
const OrdersDelivery = () => import('@/views/crm/orders-delivery/index.vue');
const Estimations = () => import('@/views/crm/estimations/index.vue');
const ShoppingCarts = () => import('@/views/crm/shopping-carts/index.vue');
const Contracts = () => import('@/views/crm/contracts/index.vue');
const BillingIn = () => import('@/views/crm/billing/in.vue');
const BillingOut = () => import('@/views/crm/billing/out.vue');
const BillingSettings = () => import('@/views/crm/billing/settings.vue');
const Marketplace = () => import('@/views/crm/marketplace/index.vue');
const ShowCase = () => import('@/views/crm/marketplace/Card.vue');
const Catalogs = () => import('@/views/crm/catalogs/index.vue');
const Analytics = () => import('@/views/crm/analytics/index.vue');
const News = () => import('@/views/crm/news/index.vue');
const JivoSeller = () => import('@/views/crm/jivo-seller/index.vue');
const Accounts = () => import('@/views/crm/bonuses/accounts.vue');
const Operations = () => import('@/views/crm/bonuses/operations.vue');
const Feedbacks = () => import('@/views/crm/feedbacks/index.vue');

const ContentManagement = () => import('@/views/crm/content-mgmt/index.vue');
const ManageSection = () => import('@/views/crm/content-mgmt/ManageSection.vue');

const Events = () => import('@/views/crm/marketing/events');
const PromoCodes = () => import('@/views/crm/marketing/promocodes');
const Reports = () => import('@/views/crm/reports/index');

const Home = () => import('@/views/crm/index.vue');
const crmRoutes = [
  {
    path: 'sales',
    name: 'sales',
    component: Sales,
    meta: {
      title: 'Продажи',
    },
  },
  // {
  //   path: 'clients',
  //   name: 'clients',
  //   component: Clients,
  //   meta: {
  //     title: 'Покупатели',
  //   },
  // },
  {
    path: 'counterparties',
    name: 'counterparties',
    // redirect: 'counterparties/suppliers',
    meta: {
      title: 'Контрагенты',
    },
  },
  {
    path: 'counterparties/suppliers',
    name: 'suppliers',
    component: Suppliers,
    meta: {
      title: 'Продавцы',
      parent: 'counterparties',
    },
  },
  {
    path: 'counterparties/contracts',
    name: 'contracts',
    component: Contracts,
    meta: {
      title: 'Договоры',
      parent: 'counterparties',
    },
  },
  {
    path: 'supplier-requests',
    name: 'supplier-requests',
    component: SuppliersRequests,
    meta: {
      title: 'Заявки',
      parent: 'counterparties',
    },
  },
  {
    path: 'counterparties/suppliers/:id',
    name: 'supplier-info',
    component: Suppliers,
    meta: {
      title: 'Инфо',
    },
  },
  {
    path: 'appeals',
    name: 'appeals',
    component: Appeals,
    meta: {
      title: 'Обращения',
      parent: 'appeals',
    },
  },
  {
    path: 'shopping-carts',
    name: 'shopping-carts',
    component: ShoppingCarts,
    meta: {
      title: 'Корзина',
      parent: 'shopping-carts',
    },
  },
  {
    path: 'orders',
    name: 'orders',
    meta: {
      title: 'Заказы',
      parent: 'orders',
    },
  },
  {
    path: 'orders/all',
    name: 'orders-all',
    component: Orders,
    meta: {
      title: 'Заказы',
      parent: 'orders',
    },
  },
  {
    path: 'orders/delivery',
    name: 'orders-delivery',
    component: OrdersDelivery,
    meta: {
      title: 'Доставки',
      parent: 'orders',
    },
  },
  {
    path: 'estimations',
    name: 'estimations',
    component: Estimations,
    meta: {
      title: 'Оценки',
      parent: 'estimations',
    },
  },
  {
    path: 'procurements',
    name: 'procurements',
    component: Procurements,
    meta: {
      title: 'Закупки',
    },
  },
  {
    path: 'clients',
    name: 'clients',
    meta: {
      title: 'Клиенты',
      parent: 'clients',
    },
  },
  {
    path: 'clients/list',
    name: 'clients-list',
    component: Clients,
    meta: {
      title: 'Клиенты',
      parent: 'clients',
    },
  },
  {
    path: 'clients/refunds',
    name: 'clients-refunds',
    component: Refunds,
    meta: {
      title: 'Возвраты',
      parent: 'clients',
    },
  },
  {
    path: 'billing',
    name: 'billing',
    // redirect: 'billing/in',
    meta: {
      title: 'Биллинг',
      parent: 'billing',
    },
  },
  {
    path: 'billing/in',
    name: 'billing-in',
    component: BillingIn,
    meta: {
      title: 'Входящие',
      parent: 'billing',
    },

  },
  {
    path: 'billing/out',
    name: 'billing-out',
    component: BillingOut,
    meta: {
      title: 'Исходящие',
      parent: 'billing',
    },
  },
  {
    path: 'catalogs',
    name: 'catalogs',
    component: Catalogs,
    meta: {
      title: 'Каталоги',
      parent: 'catalogs',
    },
  },
  {
    path: 'settings',
    name: 'settings',
    component: BillingSettings,
    meta: {
      title: 'Настройки биллинга',
      parent: 'settings',
    },
  },
  {
    path: 'calls',
    name: 'calls',
    component: Calls,
    meta: {
      title: 'Контрагенты',
      parent: 'calls',
    },
  },
  {
    path: 'marketplace/showcases',
    name: 'showcases',
    component: Marketplace,
    meta: {
      title: 'Витрина',
      parent: 'marketplace',
    },
  },
  {
    path: 'marketplace/showcase/:id',
    name: 'showcase',
    component: ShowCase,
    meta: {
      title: 'Витрина',
      parent: 'marketplace',
    },
  }, {
    path: 'marketplace/catalog-order',
    name: 'catalog-order',
    component: CatalogOrder,
    meta: {
      title: 'Каталоги',
      parent: 'marketplace',
    },
  },

  {
    path: 'analytics',
    name: 'analytics',
    component: Analytics,
    meta: {
      title: 'Аналитика',
      parent: 'analytics',
    },
  },
  {
    path: 'content-mgmt',
    name: 'content-mgmt',
    component: ContentManagement,
    meta: {
      title: 'Управление контентом',
      parent: 'content-mgmt',
    },
  },
  {
    path: 'content-mgmt/manage-section/:id',
    name: 'manage-section',
    component: ManageSection,
    meta: {
      title: 'Создание/редактирование настроек',
      parent: 'content-mgmt',
    },
  },
  {
    path: 'news',
    name: 'news',
    component: News,
    meta: {
      title: 'Новости и акции',
      parent: 'news',
    },
  },
  {
    path: 'jivo-seller',
    name: 'jivo-seller',
    component: JivoSeller,
    meta: {
      title: 'Обращения продавцов',
      parent: 'jivo-seller',
    },
  },
  {
    path: 'bonuses',
    name: 'bonuses',
    meta: {
      title: 'Бонус',
      parent: 'bonuses',
    },
  },
  {
    path: 'bonuses/accounts',
    name: 'bonuses-accounts',
    component: Accounts,
    meta: {
      title: 'Счета',
      parent: 'bonuses',
    },
  },
  {
    path: 'bonuses/operations',
    name: 'bonuses-operations',
    component: Operations,
    meta: {
      title: 'Операции',
      parent: 'bonuses',
    },
  },
  {
    path: 'feedbacks',
    name: 'feedbacks',
    component: Feedbacks,
    meta: {
      title: 'Отзывы',
      parent: 'feedbacks',
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Clever CRM',
    },
  },
  {
    path: 'marketing',
    name: 'marketing',
    // redirect: 'billing/in',
    meta: {
      title: 'Маркетинг',
      parent: 'marketing',
    },
  },
  {
    path: 'marketing/events',
    name: 'events',
    component: Events,
    meta: {
      title: 'Мероприятия',
      parent: 'marketing',
    },

  },
  {
    path: 'marketing/promocodes',
    name: 'promocodes',
    component: PromoCodes,
    meta: {
      title: 'Промокоды',
      parent: 'marketing',
    },
  },
  {
    path: 'reports',
    name: 'reports',
    component: Reports,
    meta: {
      title: 'Отчеты',
      parent: 'reports',
    },
  },
];

export default crmRoutes;
