import Vue from 'vue';
import VueRouter from 'vue-router';
import wmsRoutes from '@/router/wms/routes';
import crmRoutes from '@/router/crm/routes';
import dictionariesRoutes from '@/router/dictionaries/routes';
import store from '@/store';
import Home from '../views/Home.vue';

const wmsComponent = () => import('@/router/wms/index');
const crmComponent = () => import('@/router/crm/index');
const dictionariesComponent = () => import('@/router/dictionaries/index');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/wms',
    component: wmsComponent,
    children: [...wmsRoutes],
  },
  {
    path: '/crm',
    component: crmComponent,
    children: [...crmRoutes],
  },
  {
    path: '/dictionaries',
    component: dictionariesComponent,
    children: [...dictionariesRoutes],
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

const DEFAULT_TITLE = 'Clever';
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
  const { availablePages } = store.state.user;
  const { sideMenuList } = store.state;
  const sideMenu = sideMenuList.find((i) => i.path === to.name);
  if (sideMenu) {
    const rt = sideMenu.child.filter((i) => Object.keys(availablePages).includes(i.path));
    if (rt.length > 0) {
      router.push({
        name: rt[0].path,
      });
    }
  }
});

router.beforeEach((to, from, next) => {
  const { availablePages } = store.state.user;
  const available = Object.keys(availablePages).some((i) => i === to.name);
  if (available) {
    next();
  } else {
    router.app.$toast.error('У вас нет доступа к данному ресурсу');
    next(false);
  }
});

export default router;
