import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/src/locale/ru.ts';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#007450',
        secondary: '#595959',
        // accent: '#82B1FF',
        error: '#F15B3B',
        // info: '#595959',
        success: '#4CAF50',
        warning: '#fb8c00',
        disabled: '#A3A3A3',
        normal: '#FFFFFF',
      },
    },
  },
  lang: {
    locales: { ru },
    current: 'ru',
  },
});
export default vuetify;
