<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        readonly
        v-bind="attrs"
        v-on="on"
        solo
      >
        <template #append>
          <v-icon>
            mdi-calendar
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      locale="ru"
      first-day-of-week="1"
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  name: 'cm-date-picker',
  props: ['columnName'],
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu: false,
    };
  },
  watch: {
    date: {
      handler(val) {
        if (val) {
          this.$emit('submit', {
            key: this.columnName,
            value: moment(this.date).format('YYYY-MM-DDTHH:mm:ss.sss[Z]'),
          });
        }
      },
    },
  },
};
</script>

<style scoped>

</style>
