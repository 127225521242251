const getters = {
  getEntity: (state) => (entity) => state[entity],
};

const mutations = {
  setEntity: (state, payload) => {
    state[payload.state] = payload.data;
  },
};

const actions = {
  actionSetEntity: ({ commit }, payload) => {
    commit('setEntity', payload);
  },
};

const state = {
  requestStatus: [
    'DRAFT', 'ACTIVE', 'ARCHIVE',
  ],
  refundRequestEnums: {
    SIZE: 'Не подошел размер, фасон, цвет, длина',
    COLOR: 'Оттенок не соответствует фото',
    EQUIPMENT: 'Неправильная комплектация',
    DEFECT: 'Наличие дефекта, брака',
    OTHER: 'Другое',
  },
  contractStatuses: [
    'INACTIVE', 'ACTIVE', 'ARCHIVE',
  ],
  receptionStatuses: ['WAITING', 'ACCEPTED', 'CANCELED'],
  statusEnums: {
    New: 'Новый',
    CREATED: 'Создан',
  },
  paymentTypeEnums: {
    CARD: 'Картой',
    CASH: 'Наличные',
    POS: 'Картой при получении',
    CREDIT: 'Кредит',
    APPLEPAY: 'Applepay',
    GOOGLEPAY: 'Googlepay',
    INSTALLMENT: 'Рассрочка',
  },
  billingStatus: {
    DONE: 'Завершен',
  },
  orderStatusEnums: {
    NEW: 'Новый',
    WAITING: 'На ожидании',
    ON_DELIVERY: 'Доставляется',
    ON_SHIPMENT: 'На отгрузке',
    READY_FOR_SHIPMENT: 'Готово к отгрузке',
    ON_SELECTION: 'На отборе',
    DELIVERED: 'Доставлено',
    FINISHED: 'Завершен',
    CANCELED: 'Отменен',
  },
  routeSheetEnums: {
    NEW: 'Новый',
    READY_FOR_SHIPMENT: 'Готово к отгрузке',
    ON_DELIVERY: 'Выполняется',
    FINISHED: 'Выполнено',
    DELETED: 'Удалено',
    CANCELED: 'Отменено',
  },
  RouteSheetStatusEnums: {
    NEW: 'Новый',
    ON_DELIVERY: 'Выполняется',
    FINISHED: 'Выполнено',
  },
  appealMethodEnums: {
    MOBILE: 'Телефон',
    REQUEST: 'Заявка',
    SITE: 'Сайт',
    WHATSAPP: 'Whatsapp',
    TELEGRAM: 'Telegram',
    APPLICATION: 'Приложение',
  },
  appealReasonEnums: {
    REFUND: 'Возврат денег',
    DEFECTIVE_GOODS: 'Некачественный товар',
    NOT_DELIVERY_GOODS: 'Не доставили товар',
    OTHER: 'Другое',
  },
  appealLevelEnums: {
    HIGH: 'Высокий',
    AVERAGE: 'Средний',
    LOW: 'Низкий',
  },
  appealStatusEnums: {
    NEW: 'Новое',
    IN_WORK: 'В работе',
    FINISHED: 'Завершено',
    EXPIRED: 'Просрочено',
    CANCELED: 'Отменено',
  },
  orderStatusColorEnums: {
    NEW: '#aaa5a5',
    ON_DELIVERY: '#3461D9FF',
    DELIVERED: '#3461D9FF',
    FINISHED: '#118b06',
    CANCELED: '#bb0031',
  },
  enums: {
    CREATED: 'Создан',
    DECLINE: 'Отказано',
    ACCEPTED: 'Принято',
    POS: 'Картой при получении',
    APPROVED: 'Подтверждено',
    DRAFT: 'Черновик',
    PUBLISHED: 'Опубликован',
    COMPLETED: 'Завершен',
    DELETED: 'Удалён',
    CARD: 'Картой',
    CASH: 'Наличные',
    UNPAID: 'Не оплачен',
    PAID: 'Оплачен',
    DONE: 'Выполнено',
    CREDIT: 'Рассрочка',
    WAITING: 'Ожидается подтверждение банком',
  },
  refundStatusEnums: {
    CREATED: 'Оформлен',
    CONFIRMED: 'Подтвержден',
    ACCEPTED: 'Принят',
    REJECTED: 'Отклонен',
    FINISHED: 'Завершен',
  },
  refundStatusColorEnums: {
    CREATED: '#4CAF50',
    CONFIRMED: '#3461D9FF',
    ACCEPTED: '#3461D9FF',
    FINISHED: '#118b06',
    REJECTED: '#bb0031',
  },
  feedbacksStatuses: {
    1: 'Неотвеченный',
    2: 'На модерации',
    3: 'Ответ опубликован',
    4: 'Требуется доработка',
  },
  eventStatusEnums: {
    NEW: 'Новый',
    ACTIVE: 'Активный',
    FINISHED: 'Завершено',
  },
  eventStatusColorEnums: {
    NEW: '#737373',
    ACTIVE: '#39B54A',
    FINISHED: '#F44336',
  },
  eventTypeEnums: {
    NEW_CLIENT: 'Новый клиент',
    PERSONAL: 'Персональный',
    COMMON: 'Общий',
  },
  promoStatusEnums: {
    ACTIVATED: 'Активный',
    COMPLETED: 'Использован',
    CANCELED: 'Отменненый',
  },
  receiptTypeEnums: {
    PICKUP: 'Самовывоз',
    DELIVERY: 'Доставка',
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
